.newContent{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
}
.image_tintuc{
    width: 80%;
}
.contentParagraph{
    width: 80%;
}
.image_tintuc_top100{
    width: 70%;
}
.image_tintuc_top100_huychuong{
    height: 500px;
}
@media screen and (max-width: 767px) {
   .contentParagraph{
    width: 100%;
   }
   .image_tintuc{
    width: 100%;
   }
   .image_tintuc_top100_huychuong{
    width: 100%;
    height: auto;
}
.image_tintuc_top100{
    width: 100%;
}
.text_news{
    font-size: 17px;
}
.text_news_letrungbai{
    font-size: 15px;
}
}