.line{
    border: 3px solid #1d4794;
    margin-top: 50px;
}   
.productTitle{
    display: flex;
   justify-content: end;
}

.divline{
    border-bottom: 5px #1d4794 solid;
  }
  @media screen and (max-width: 767px) {
    .productTitle{
        width: 100%;
    }
    .productTitle{
        display: flex;
       justify-content: center;
    }
    .divline{
       display: none;
      }
}