.line {
    border: 3px solid #1d4794;
    margin-top: 50px;
}

.video-container {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    width: "800px";
    height: "500px";
}

.responsive_item_img {
    width: 85%;
}
.productLine{
    margin-top: 20px;
    border-bottom: 5px #1d4794 solid;
}

@media screen and (max-width: 767px) {
    .responsive_item_img {
        width: 100%;
    }
    .video-container{
        margin-top: 30px;
        display: flex;
        justify-content: center;
        width: 100%;
        height: auto;
    }
}