.footer {
  background-color: #1d4794;
  color: white;
  bottom: 0;
  width: 100%;
  margin-top: 30px;
  padding: 10px;
}

@media screen and (max-width: 767px) {

  .footer_content_responsive {
    width: 100%;
    font-size: 8px;
  }
}