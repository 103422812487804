.line{
    border: 3px solid #1d4794;
    margin-top: 50px;
}
.image_accessory_detail{
    height: 300px;
    width: 100%;
}
@media screen and (max-width: 767px) {
    .image_accessory_detail{
        width: 100%;
        height: 200px;
    }
}