.videoYoutube{
    margin-top: 100px;
}
.content{
    color: #1d4794;
    font-weight: bold;
}
.number{
    color: #1d4794;
    font-weight: bold;
    font-size: 9vh;
}
.image_number{
    width: 100%;
    height: 100%;
}
.introduce{
    color: #1d4794;
}
@media screen and (max-width: 767px) {
    .responsive_youtube{
        width: 100%;
        height: auto;
    }
}