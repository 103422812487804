.conditionText {
  font-weight: bolder;
  color: #1d4794;
}
.divline{
  border-bottom: 5px #1d4794 solid;
}
.image .image_reason {
  width:100%;
  height: 200px;
}

.location_text{
  font-size: 40px;
}
@media screen and (max-width: 767px) {
  .responsive_km{
    width: 100%;
  }
  .responsive_grid_item{
    width: 100%;
  }
  .responsive_text{
    font-size: 10px;
  }

  .image .image_reason {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 200px;
  }
  .location_text{
    font-size: 30px;
  }
  
}