/* Menu.css */
.toggle-menu {
    display: none;
    /* Hide by default */
}

.menu-button {
    cursor: pointer;
}

/* Media query for responsiveness */
@media screen and (max-width: 767px) {
    .menu {
        display: none;
        /* Hide menu items by default on small screens */
    }

    .dropdown {
        display: none;
    }

    .toggle-menu {
        display: flex;
        /* Show menu toggle button on small screens */
        justify-content: center;
        /* Center content horizontally */
        align-items: center;
        /* Center content vertically */
    }

    .menu.show {
        display: block;
        /* Show menu items when menu toggle button is clicked */
    }

    .dropdown.show {
        display: block;
        /* Show menu items when menu toggle button is clicked */
    }

    .dropdown-content {
        display: block; 
        position: relative;
        background-color: white;
        min-width: 160px;
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
        z-index: 0;
    }

    .menu-button {
        align-self: center;
    }
    .responsivelogo{
        width: 90%;
    }

}