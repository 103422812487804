.header .title {
  background-color: #1d4794;
  color: white;
  font-weight: bold;
  height: 80px;
}

/* .menu .a{
    background-color: white;
    color: #1d4794;
    text-decoration: none;
} */
.menu a {
  color: #1d4794;
  text-decoration: none;
  font-weight: bolder;
  width: 100%;
  height: 100%;
  display: block;
}
.menubar {
  border-bottom: #1d4794 solid 3px;
}
@media screen and (max-width: 600px) {
  .menubar {
      flex-direction: column;
  }

  .menubar a {
      display: block;
      padding: 5px 0;
  }
  .menu{
    width: 100%;
  }
}

.menu a:hover {
  background-color: #1d4794;
  text-decoration: none;
  color: white;
}
.dropdown a {
  text-decoration: none;
  color: #1d4794;
  font-weight: bolder;
  width: 100%;
}

.dropdown:hover .dropdown-content {
  display: block;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.dropdown-content a {
  color: #1d4794;
  text-decoration: none;
  padding: 12px 16px;
  display: block;
}
.dropdown-content a:hover {
  background-color: #1d4794;
  text-decoration: none;
  width: 100%;
  color: white;
}
